import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby";
import { Layout, SEO, CJumbotron, CBreadCrumb, LWrap, CNewsList, CUtilityBtnList, CPagingList } from "../../components/_index"
import yearrangeGet from "../../utils/yearrange-get"
import releaseGet from "../../utils/release-get"
import releaseChoice from "../../utils/release-choice"

// markup
const SubPage = ({ pageContext }: { pageContext: { startYear: number, endYear: number, currentYear: number } }) => {
  const frontmatter = {
    title: "ニュースリリース",
    description: "ロイヤルパークホテルズのニュースリリースページです。ホテルのニュースリリースを掲載しています。"
  }
  const startyear = pageContext.startYear
  const endyear = pageContext.endYear
  const currentyear = pageContext.currentYear
  const newsreleasedata = releaseChoice(releaseGet(), 0).filter(function (element: any) {
    const year = new Date(element.node.publishedAt).getFullYear()
    return (year == currentyear)
  })
  const btnData = yearrangeGet(startyear, endyear).map((item) => {
    const linkurl = (item == endyear) ? `/release/` : `/release/${item}/`
    const current = (item == currentyear) ? true : false
    const json = {
      label: `${item}年`,
      link: {
        href: linkurl
      },
      current: current
    }
    return json
  })

  const [stateShow, setStateShow] = useState(btnData.length)
  const resizeFlag = useRef<boolean>(false)
  const moreClick = () => {
    setStateShow(btnData.length)
  }

  useEffect(() => {
    if (window.innerWidth < 768 && !resizeFlag.current) {
      setStateShow(6)
      resizeFlag.current = true
    }
  })


  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        data={{
          title: {
            en: <>NEWS RELEASE</>,
            ja: 'ニュースリリース'
          },
          img: {
            src: '/assets/images/release/kv.png'
          },
          imgSp: {
            src: '/assets/images/release/kv.png'
          }
        }}
      />
      <CBreadCrumb data={{
        parent: [
        ],
        current: {
          label: frontmatter?.title
        }
      }} />
      <div className="l_sect03">
        <LWrap>
          <CUtilityBtnList
            data={btnData.slice(0, stateShow)}
          />
          {stateShow < btnData.length && (
            <p className="u_tac u_mt30">
              <button className="c_linkText" onClick={moreClick}>もっと見る<i className="icon-plus"></i></button>
            </p>
          )}
        </LWrap>
      </div>
      <section className="l_sect u_bgGray">
        <LWrap>
          <CNewsList data={newsreleasedata} />
        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;
