import { useStaticQuery, graphql } from 'gatsby';

const releaseGet = () => {
  const newsdataquery = useStaticQuery(graphql
    `query{
        allMicrocmsNewsrelease(sort: {fields: sortIndex, order: ASC}) {
          edges {
            node {
              publishedAt
              pdf {
                url
              }
              link {
                blank
                href
              }
              title
              hotel {
                id
              }
              newsreleaseId
            }
          }
        }
      }`
  )
  const newsdata = newsdataquery.allMicrocmsNewsrelease.edges
  return newsdata
}

export default releaseGet